<template>
    <div class="text-center">
        <v-dialog width="700" v-model="dialog">
            <template v-slot:activator="{ on }">
                <!-- <v-btn class="mt-1"
              text
              v-on="on"
              
            >
              <v-icon>mdi-account-circle</v-icon>
            </v-btn> -->
                <v-btn class="" depressed small v-on="on">
                    {{ newList.surname }} {{ newList.name }}
                    {{ newList.patronymic }}

                    <v-icon color="blue" right> mdi-open-in-new </v-icon>
                </v-btn>
                <!-- <v-btn text class="success" dark v-on="on">Изменить</v-btn> -->
            </template>
            <v-card>
                <v-toolbar dark color="primary">
                    <v-toolbar-title>Абитуриент</v-toolbar-title>
                </v-toolbar>
                <!-- <v-card-title class="headline" primary-title
          >Изменить регистрационные даннные</v-card-title
        > -->
                <v-card-text>
                    <v-form class="px-3 mt-5">
                        <v-row>
                            <v-col>
                                <v-text-field
                                    disabled
                                    label="ID"
                                    v-model="newList.enrolleeId"
                                >
                                </v-text-field>

                                <v-text-field
                                    label="Фамилия"
                                    v-model="newList.surname"
                                >
                                </v-text-field>
                                <v-text-field
                                    label="Имя"
                                    v-model="newList.name"
                                >
                                </v-text-field>
                                <v-text-field
                                    label="Отчество"
                                    v-model="newList.patronymic"
                                >
                                </v-text-field>
                                <v-text-field
                                    v-mask="'##.##.####'"
                                    label="Дата рождения"
                                    v-model="newList.birth"
                                >
                                </v-text-field>
                                <v-text-field
                                    label="СНИЛС"
                                    v-model="newList.snils"
                                    v-mask="'###-###-### ##'"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col>
                                <v-text-field
                                    label="Первый родитель"
                                    v-model="newList.parentNameOne"
                                >
                                </v-text-field>
                                <v-text-field
                                    label="Второй родитель"
                                    v-model="newList.parentNameTwo"
                                >
                                </v-text-field>
                                <v-text-field
                                    v-mask="'#(###)###-##-##'"
                                    label="Телефон"
                                    v-model="newList.phone"
                                >
                                </v-text-field>
                                <v-text-field
                                    label="Почта"
                                    v-model="newList.email"
                                >
                                </v-text-field>
                                <v-text-field
                                    label="Школа"
                                    v-model="newList.currentSchool"
                                >
                                </v-text-field>
                                <v-text-field
                                    label="День открытых дверей"
                                    v-model="newList.openDoors"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                        Предметы
                       <v-layout  wrap>
                            <v-checkbox
                                class="pl-3"
                                v-for="item in newList.availSubs"
                                :label="item"
                                v-model="subprofilesEnabled"
                                :value="item"
                                @change="updateSubprofiles()"
                                :key="item">
                                {{ item }}
                            </v-checkbox>
                            </v-layout>
                        
                        <div class="radioGroup">
                            <v-radio-group v-model="status" row @change="changeStatus(this)">
                                <v-radio 
                                    label="Создан"
                                    value="created"
                                ></v-radio>
                                <v-radio
                                    label="Необходимо подтвердить"
                                    value="confirmation-process"
                                ></v-radio>
                                <v-radio
                                    label="Подтвержден"
                                    value="confirmed"
                                ></v-radio>
                                <v-radio
                                    label="Приглашен"
                                    value="invited"
                                ></v-radio>
                                <v-radio disabled
                                    label="Удален"
                                    value="deleted"
                                ></v-radio>
                            </v-radio-group>
                        </div>

                        <v-card-actions class="flex px-5 justify-center">
                            <v-btn-toggle>
                                <v-btn
                                    @click="sendMail('registration')"
                                    ><v-icon>mdi-check-circle-outline</v-icon
                                    >Регистрация</v-btn
                                >
                                <v-btn
                                    @click="sendMail('confirm')"
                                    ><v-icon>mdi-check-circle-outline</v-icon
                                    >Подтверждение</v-btn
                                >
                                <v-btn
                                    @click="sendMail('invite')"
                                    >Приглашение<v-icon right
                                        >mdi-cake</v-icon
                                    ></v-btn
                                >
                            </v-btn-toggle>
                        </v-card-actions>
                        <v-card-actions class="flex px-5 justify-space-between"
                            ><v-btn
                                text
                                class="success mx-0 mt-3"
                                @click="saveEnrolee"
                                >Сохранить</v-btn
                            >
                            <v-btn text class="mx-0 mt-3" @click="exportPDF"
                                >PDF</v-btn
                            >
                            <v-btn
                                text
                                class="error mx-0 mt-3"
                                @click="deleteEnrollee"
                                >Удалить</v-btn
                            ></v-card-actions
                        >
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import axios from 'axios'
import { bus } from '../main'
export default {
    components: {},
    created() {
        this.allSubprofiles = this.newList.availSubs
        this.subprofilesEnabled = this.newList.subprofile
    },
    data() {
        return {
            dialog: false,
            dataElement: {},
            status: this.element.status,
            allSubprofiles: [],
            subprofilesEnabled: []
            /* selectedSubProfiles:  */
        }
    },
    props: {
        element: {
            type: Object,
            default() {
                return {}
            },
        },
    },
    computed: {
        newList: function () {
            return (this.dataElement = Object.assign(
                this.dataElement,
                this.element
            ))
        },
    },
    methods: {
        async changeStatus() {
            const answer = await axios.post('/api/changeEnrolleeStatus', {enrolleeId:this.element.enrolleeId, status: this.status})
            console.log(answer);
            if(answer) {
                bus.$emit('snackbar', answer.data.msg)
            }

        },
        sendMail(type) {
            this.dataElement.type = type
            axios.post('/api/send-mail', this.dataElement).then((data) => {
                bus.$emit('snackbar', 'Действие выполнено')
            })
        },
        exportPDF() {
            this.$axios
                .post('/pdf', this.dataElement, { responseType: 'arraybuffer' })
                .then((data) => {
                    var fileURL = window.URL.createObjectURL(
                        new Blob([data.data], {
                            type: 'application/pdf',
                        })
                    )
                    window.open(fileURL)
                })
        },
        saveEnrolee() {
            this.$axios
                .post('/api/edit-enrollee/', this.dataElement)
                .then((res) => {
                    if (res.data.status === 'success') {
                        this.dialog = false
                        bus.$emit('snackbar', 'Успешно сохранено')
                        this.$emit('changesSaved', this.newList)
                    }
                })
        },
        deleteEnrollee() {
            axios.post('/api/delete-enrollee', this.dataElement).then((res) => {
                if (res.data.status == 'success') {
                    bus.$emit('snackbar', res.data.msg)
                    this.dialog = false
                }
            })
        },
        async updateSubprofiles() {
            const answer = await axios.post('/api/change-subprofiles-enrollee',  {enrolleeId: this.dataElement.enrolleeId, subprofiles: this.subprofilesEnabled})
            if (answer.data.success) {
                bus.$emit('snackbar', 'Список предметов изменён')
            }
        }
    },
    validations: {
        /*     snils: {
      required,
      snilsVal: (val) => /^\d{3}-\d{3}-\d{3} \d{2}$/i.test(val),
    },
    birth: {
      required,
      birthVal: (val) =>
        /^\s*(3[01]|[12][0-9]|0?[1-9])\.(1[012]|0?[1-9])\.((?:19|20)\d{2})\s*$/i.test(
          val
        ),
    },
    phone: {
      required,
      phoneVal: (val) => /^(8-?|\+?7-?)?(\(?\d{3}\)?)-?(\d-?){6}\d$/i.test(val),
    }, */
    },
}
</script>
<style scoped>
.radioGroup {
    display: flex;
    justify-content: center;
}
</style>