/* import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {}
});
 */
import {bus} from '../main.js'

import Vue from 'vue'
import Vuex from 'vuex'
/* import axios from 'axios' */
import router from '/src/router/index.js'
import axios from '../service'
Vue.prototype.$axios = axios;

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        status: "",
        token: localStorage.getItem("token") || "",
        user: "",
        show: false,
        selected: [],
    },
    mutations: {
        set_show(state) {
            state.show = true;
        },
        auth_request(state) {
            state.status = "loading";
        },
        auth_success(state, token, user) {
            state.status = "success";
            state.token = token;
            state.user = user;
        },
        auth_error(state) {
            state.status = "error";
        },
        logout(state) {
            state.status = "";
            state.token = "";
        },
        set_profiles(state, profiles) {
            state.profiles = profiles
        },
        changeSelected(state, selected) {
            if(!state.selected.includes(selected)){          //checking weather array contain the id
                state.selected.push(selected);               //adding to array because value doesnt exists
            }else{
                state.selected.splice(state.selected.indexOf(selected), 1);  //deleting
            }
        },
        closeModal(state, selected) {
            state.selected = []
        }
    },
    actions: {
        login({ commit }, user) {
            return new Promise((resolve, reject) => {
                commit("auth_request");
                axios({ url: "/login", data: user, method: "POST" })
                    .then((resp) => {
                        if (resp.data.success == false) {
                            resolve(401)
                             }
                        const token = resp.data.token;
                        const user = resp.data.user;
                        localStorage.setItem("token", token);
                        localStorage.setItem("user", user);
                        // Add the following line:
                        axios.defaults.headers.common["Authorization"] = token;
                        commit("auth_success", token);
                        resolve(resp);
                    })
                    .catch((err) => {
                        commit("auth_error");
                        localStorage.removeItem("token");
                        reject(err);
                    });
            });
        },
        register({ commit }, user) {
            return new Promise((resolve, reject) => {
                commit("auth_request");
                axios({ url: "/signup", data: user, method: "POST" })
                    .then((resp) => {
                        const token = resp.data.token;
                        const user = resp.data.user;
                        localStorage.setItem("token", token);
                        // Add the following line:
                        axios.defaults.headers.common["Authorization"] = token;
                        commit("auth_success", token, user);
                        resolve(resp);
                    })
                    .catch((err) => {
                        commit("auth_error", err);
                        localStorage.removeItem("token");
                        reject(err);
                    });
            });
        },
        logout({ commit }) {
            return new Promise((resolve) => {
                commit("logout");
                localStorage.removeItem("token");
                delete axios.defaults.headers.common["Authorization"];
                resolve();
            });
        },
        verifyToken({ commit }) {
           axios.post("/api/verify-token", {}).then((res) => {
                const profiles = res.data.profiles
                if (!res.data.success) {
                    router.push("/login");
                } else {
                    commit("set_show");
                    commit("set_profiles", profiles)
                }
            });
        },
    },



    getters: {
        isLoggedIn: (state) => !!state.token,
        authStatus: (state) => state.status,
        profilesList: (state) =>
            state.profiles
        ,
        SELECTED: state => state.selected
    },
});