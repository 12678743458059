<template>
    <div style="background-color: #f5f5f5; height: 100%">
        <div class="pa-5">
            <v-container class="my-5">
                <v-card
                    class="mx-auto px-5 py-5 mt-5"
                    min-width="30%"
                    elevation="2"
                >
                    <v-simple-table height="200px">
                        <template v-slot:default>
                           
                            <tbody>
                                <tr v-for="item in synt" :key="item.name">
                                    <td>{{ item.name }}</td>
                                    <td>{{ item.prop }}</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card>
                <v-card
                    v-for="html in htmls"
                    :key="html.name"
                    class="mx-auto px-5 py-5 mt-5 profileInfo"
                    min-width="30%"
                    elevation="2"
                >
                    <v-card-title>{{ html.about }}</v-card-title>

                    <v-card-text class="pb-0">
                        <v-form class="">
                            <div>
                                <!-- <vue-editor v-model="html"></vue-editor> -->
                                <vue-editor v-model="html.body"></vue-editor>
                            </div>
                        </v-form>
                    </v-card-text>
                    <v-card-actions
                        class="pt-0 mt-5 d-flex justify-space-between"
                    >
                        <v-btn text @click="reveal = false"> Закрыть </v-btn>
                        <v-btn
                            text
                            color="green"
                            @click="submit(html.body, html._id)"
                            >Сохранить</v-btn
                        >
                    </v-card-actions>
                </v-card>
            </v-container>
        </div>
    </div>
</template>
<script>
import { VueEditor } from 'vue2-editor'
import { bus } from '../main'
export default {
    components: { VueEditor },
    data() {
        return {
            htmls: null,
            reveal: false,
            html: 'da',
            synt: [
                {
                    name: "Имя",
                    prop: 'name',
                },
                {
                    name: 'Фамилия',
                    prop: 'surname',
                },
                {
                    name: 'Отчество',
                    prop: 'patronymic',
                },
                {
                    name: 'Предметы',
                    prop: 'subprofile',
                },
                {
                    name: 'ID',
                    prop: 'enrolleeId',
                },
                {
                    name: 'Дата рождения',
                    prop: 'birth',
                },
                {
                    name: 'СНИЛС',
                    prop: 'snils',
                },
                {
                    name: 'Телефон',
                    prop: 'phone',
                },
                {
                    name: 'Почта',
                    prop: 'email',
                },
            ],
        }
    },
    methods: {
        submit(html, id) {
            this.$axios({
                url: '/api/mails',
                data: { id: id, html: html },
                method: 'POST',
            }).then((answer) => {
                bus.$emit('snackbar', answer.data.msg)
            })
        },
    },
    created() {
        this.$axios.get('/api/mails', {}).then((res) => {
            if (res.data.status == 'success') {
                this.htmls = res.data.data
            }
        })
    },
}
</script>
<style>
.v-card--reveal {
    bottom: 0;
    opacity: 1 !important;
    /*  position: absolute; */
    width: 100%;
}
</style>