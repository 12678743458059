<template>
<v-snackbar
      v-model="snackbar"
      :timeout="timeout"
    >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Закрыть
        </v-btn>
      </template>
    </v-snackbar>
</template>
<script>
import {bus} from '../main'
export default {
  data: () => ({
    snackbar: false,
    text: "My timeout is set to 2000.",
    timeout: 5000,
  }),
  props: {
      msg: {
          type: String,
          default(){
              return ''
          }
      }
  },
  created() {
      bus.$on('snackbar', data => {
          this.text = data;
          this.snackbar = true;
          
      })
  }
};
</script>