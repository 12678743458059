<template>
    <v-app>
      <transition mode="out-in">
        <router-view></router-view>
      </transition>
   </v-app> 
</template>

<script>
export default {
  name: 'App',

  components: {
  },

  data: () => ({
  }),
};
</script>


<style>
body {
    background-color:#f5f5f5
}
</style>