<template>
    <div class="list">
        <Element
            v-for="enrolleeId in this.$store.getters.SELECTED"
            :enrolleeId="enrolleeId"
            :key="enrolleeId"
        />
    </div>
</template>

<script>
import Element from './Element.vue'
export default {
    components: {
        Element,
    },
}
</script>

<style scoped>
.list {
    display: grid;
    margin-top: 10px;
    grid-template-columns: repeat(2, 1fr);
    gap: 5px;
    /* grid-auto-rows: 200px; */
}
</style>
