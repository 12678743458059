/* import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store"; */
import vuetify from "./plugins/vuetify";
import '@mdi/font/css/materialdesignicons.css'
/* Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app"); */


import Vue from 'vue';
/* import VueRouter from 'vue-router'; */
import Vuelidate from 'vuelidate'
/* Vue.use(VueRouter); */
Vue.use(Vuelidate)
import App from './App.vue'
import Axios from 'axios'
import store from './store'
import router from './router'
import VueMask from 'v-mask'
import VueMeta from 'vue-meta'
import axios from './service'
Vue.prototype.$axios = axios;
Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})
Vue.use(VueMask);
Vue.prototype.$http = Axios;
const token = localStorage.getItem('token')

if (token) {
  Vue.prototype.$http.defaults.headers.common['Authorization'] = token
}

/* import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'



Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons) */

Vue.config.productionTip = false

export const bus = new Vue();

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App),
}).$mount('#app');