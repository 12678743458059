<template>
  <v-app>
    <div v-if="this.$store.state.show" class="fill-height">
      <v-navigation-drawer app v-model="drawer" dark permanent>
        <v-list-item class="px-2">
          <v-list-item-avatar>
            <v-img src="https://reg1535.ru/img/logo_1535.c617b9f0.svg"></v-img>
          </v-list-item-avatar>
          <v-list-item-title>{{ getUsername }}</v-list-item-title>
        </v-list-item>
        <v-divider></v-divider>
    <!--     <v-layout column align-center>
          <v-flex class="mt-4 mb-3">
            <Popup></Popup>
          </v-flex>
        </v-layout> -->
        <v-flex class="mt-4 mb-3">
            <Popup></Popup>
            <htmlEditor/>
          </v-flex>
        <v-list dense>
          <v-list-item
            v-for="item in items"
            :key="item.title"
            :to="item.link"
            link
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!-- <div v-for='el in getProfiles' :key='el.profile'>{{el.profile}}</div> -->
        </v-list>
      </v-navigation-drawer>
      <v-main class="fill-height s" style="background-color: #f5f5f5;">
        <v-app-bar app flat>
          <v-icon @click="drawer = !drawer">mdi-menu</v-icon>

          <v-toolbar-title class="mx-2">
            <span class="font-weight-light">Школа №1535. </span>

            <span>Регистрации</span>
          </v-toolbar-title>
          <v-spacer />

          <v-btn text>
            <span @click="logout">Выход</span>
            <v-icon right>mdi-exit-to-app</v-icon>
          </v-btn>
        </v-app-bar>
        <transition name="fade" mode="out-in">
          <router-view class="view"></router-view>
        </transition>
        
        <!-- <v-container> -->

        <!-- </v-container> -->
      </v-main>
        <transition name="fade">
      <MultiselectPopup v-if="this.$store.state.selected.length"/>

      </transition>

      <Snackbar />
    </div>
  </v-app>
</template>

<script>
import VueJwtDecode from "vue-jwt-decode";
import Popup from "../components/AddProfile";
import htmlEditor from '../components/htmlEditor.vue'
import MultiselectPopup from '../components/MultiselectPopup.vue';
import Snackbar from "../components/Snackbar";

export default {
  metaInfo: {
      title: 'Школа №1535. Панель управления',
      htmlAttrs: {
        lang: 'ru',
        amp: true
      }
    },
  data() {
    return {
      user: {},
      data: "hehehe",
      show: false,
      drawer: false,
      items: [
        {
          title: "Регистрации",
          icon: "mdi-account",
          link: "/dashboard/enrollee-list",
        },
        {
          title: "Профили",
          icon: "mdi-account-group-outline",
          link: "/dashboard/profile",
        },
        {
          title: "Письма",
          icon: "mdi-mail",
          link: "/dashboard/mails",
        },
        {
          title: "Логи",
          icon: "mdi-text",
          link: "/dashboard/logs",
        },
      ],
      mini: true,
    };
  },
  components: { Popup, Snackbar,htmlEditor, MultiselectPopup },
  mounted(){
      let element = document.createElement('script')
      element.setAttribute('src', 'https://cdnjs.cloudflare.com/ajax/libs/FileSaver.js/2.0.0/FileSaver.min.js')
      document.head.appendChild(element)
  },
  methods: {
    logout() {
      this.$store.dispatch("logout").then(() => this.$router.push("/"));
    },
    route(link) {
      this.$router.push(link);
    },

    getUserDetails() {
      // get token from localstorage
      let token = localStorage.getItem("token");
      try {
        //decode token here and attach to the user object
        let decoded = VueJwtDecode.decode(token);
        this.user.a = decoded;
      } catch (error) {
        // return error in production env
        console.log(error, "error from decoding token");
      }
    },
    logUserOut() {
      localStorage.removeItem("user");
      this.$router.push("/login");
    },
  },
  created() {
    this.getUserDetails();
  },
  beforeCreate() {
    this.$store.dispatch("verifyToken");
  },
  computed: {
    getUsername() {
      return window.localStorage.getItem("user");
    },
    getProfiles() {
      return this.$store.getters.profilesList;
    },
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
</style>