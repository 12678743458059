<template>
    <div class="text-center">
        <v-dialog width="500" v-model="dialog">
            <template v-slot:activator="{ on }">
                <v-btn color="primary" min-width="80%" class="my-2" v-on="on"
                    >Добавить профиль</v-btn
                >
            </template>
            <v-card>
                <v-card-title class="headline" primary-title
                    >Добавить новый профиль</v-card-title
                >
                <v-card-text>
                    <v-form class="px-3">
                        <v-text-field
                            label="Название"
                            v-model="name"
                        ></v-text-field>
                        <v-text-field
                            label="Аббревиатура"
                            v-model="abbreviation"
                        ></v-text-field>
                        <v-select
                            v-model="grade"
                            :items="grades"
                            label="Класс"
                        ></v-select>
                        <v-text-field
                            label="Количество регистраций"
                            v-model.number="quantity"
                            type="number"
                        ></v-text-field>
                        <v-checkbox
                        v-model="blankArrayState"
                        :label="`Пустые регистрации: ${populateBlankArray}`"
                        ></v-checkbox>
                        <v-text-field
                            label="Предметы"
                            v-model="subprofiles"
                        ></v-text-field>{{prettySubprofiles}}s
                        <v-text-field
                            v-model="exampleEnrolleeName"
                            label="Пример"
                            outlined
                            disabled
                        ></v-text-field>
                        <div class="d-flex justify-space-between">
                            <v-btn
                                text
                                class="mx-0 mt-3"
                                @click="dialog = false"
                                >Закрыть</v-btn
                            >
                            <v-btn
                                text
                                class="success mx-0 mt-3"
                                @click="submit"
                                >Добавить</v-btn
                            >
                        </div>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import { bus } from '../main'
import axios from 'axios'
export default {
    data() {
        return {
            grades: [6, 7, 8, 9],
            name: '',
            abbreviation: '',
            grade: '7',
            quantity: null,
            dialog: false,
            blankArrayState: false,
            subprofiles: ''
        }
    },
    methods: {
        submit() {
            const profile = {
                name: this.name,
                abbreviation: this.abbreviation,
                grade: this.grade,
                quantity: this.quantity,
                blankArray: this.populateBlankArray,
                subprofiles: this.prettySubprofiles
            }
            this.$axios({
                url: '/api/create-new-profile',
                data: profile,
                method: 'POST',
            }).then((data) => {
                bus.$emit('snackbar', data.data.text)
            })
        },
        capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        }
    },
    computed: {
        exampleEnrolleeName: function() {
            return `${this.grade}-${this.abbreviation}-001`
        },
        populateBlankArray: function() {
            if(this.blankArrayState && this.quantity > 11) {
                let arr = []
                while(arr.length < 10){
                    var r = Math.floor(Math.random() * this.quantity) + 1;
                    if (r === 1 || r === this.quantity) {
                        continue
                    }
                    else {
                        if(arr.indexOf(r) === -1) arr.push(r);
                    }
                    
                }
                return arr.sort(function(a, b) {return a - b})
            } else {
                return ''
            }

        },
        prettySubprofiles: function() {
            if (this.subprofiles) {
                let arr = this.subprofiles.split(',').map((profile)=> profile.trim())
                return arr
            } else {
                return null
            }
            
        }
    },

}
</script>