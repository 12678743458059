<template>
  <div class="mycard">
    <!--     <v-toolbar dark color="primary">
      <v-toolbar-title>Форма регистрации</v-toolbar-title>
    </v-toolbar> -->
    <v-card-text class="card-text">
      Регистрация
      <v-form @submit.prevent="validate" novalidate>
        <v-select
          @change="getProfiles"
          class="mt-5"
          :items="grades"
          v-model="grade"
          item-text="grade"
          label="Выберите класс обучения"
          :error-messages="gradeErrors"
          required
          @input="$v.grade.$touch()"
          @blur="$v.grade.$touch()"
          prepend-icon="mdi-view-sequential"
        ></v-select>

        <v-row>
          <v-col cols="12" md="6" class="nopadding"
            ><v-text-field
              v-model.trim="surname"
              label="Фамилия ребёнка"
              :error-messages="surnameErrors"
              required
              @input="$v.surname.$touch()"
              @blur="$v.surname.$touch()"
              prepend-icon="mdi-account"
            ></v-text-field>
            <v-text-field
              v-model.trim="name"
              label="Имя ребёнка"
              :error-messages="nameErrors"
              required
              @input="$v.name.$touch()"
              @blur="$v.name.$touch()"
              prepend-icon="mdi-account"
            ></v-text-field>
            <v-text-field
              v-model.trim="patronymic"
              label="Отчество ребёнка"
              :error-messages="patronymicErrors"
              required
              @input="$v.patronymic.$touch()"
              @blur="$v.patronymic.$touch()"
              prepend-icon="mdi-account"
            ></v-text-field>
<!--             <v-text-field
              v-mask="'##.##.####'"
              v-model="birth"
              label="Дата рождения ребёнка"
              :error-messages="birthErrors"
              required
              @input="$v.birth.$touch()"
              @blur="$v.birth.$touch()"
              prepend-icon="mdi-calendar-range"
            ></v-text-field> -->
          </v-col>
          <v-col cols="12" md="6" class="nopadding">
            <v-text-field
              v-mask="'###-###-### ##'"
              v-model="snils"
              label="СНИЛС ребёнка"
              :error-messages="snilsErrors"
              required
              @input="$v.snils.$touch()"
              @blur="$v.snils.$touch()"
              prepend-icon="mdi-badge-account-horizontal"
            ></v-text-field>
<!--             <v-text-field
              v-mask="'#-###-###-##-##'"
              v-model="phone"
              label="Номер телефона"
              :error-messages="phoneErrors"
              required
              @input="$v.phone.$touch()"
              @blur="$v.phone.$touch()"
              prepend-icon="mdi-phone"
            ></v-text-field> -->
            <v-text-field
              type="email"
              v-model="email"
              label="Электронная почта. Укажите личную почту, а не рабочую!"
              :error-messages="emailErrors"
              required
              @input="$v.email.$touch()"
              @blur="$v.email.$touch()"
              prepend-icon="mdi-mail"
            ></v-text-field>
            <v-text-field
              v-model="currentSchool"
              label="Номер школы обучения сейчас"
              :error-messages="currentSchoolErrors"
              required
              @input="$v.currentSchool.$touch()"
              @blur="$v.currentSchool.$touch()"
              prepend-icon="mdi-school"
            ></v-text-field
          ></v-col>
        </v-row>
        <v-checkbox id="terms1" @change="$v.terms1.$touch()" v-model="terms1"
              @blur="$v.terms.$touch()">
          <template v-slot:label>
            Я согласен(на) на обработку собственных персональных данных и
            персональных данных ребенка, родителем (законным представителем)
            которого являюсь
          </template>
        </v-checkbox>

        <v-checkbox id="terms2" @change="$v.terms2.$touch()" v-model="terms2">
          <template v-slot:label>
          Я проверил(а) корректность введенных данных, особенно класс обучения на данный момент.
          </template>
        </v-checkbox>
          <v-checkbox id="terms3" @change="$v.terms3.$touch()" v-model="terms3">
          <template v-slot:label>
          Я знаю, что участие в проекте "Проба 1535" не является обязательным и что участие не дает преференций при поступлении в Школу №1535.
          </template>
        </v-checkbox>

      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <vue-recaptcha
        ref="recaptcha"
        size="invisible"
        :sitekey="sitekey"
        @verify="submitEnrollee"
        @expired="onCaptchaExpired"
      />

      <v-btn
        type="submit"
        color="primary"
        @click="validate"
        
        style="width: 120px"
        >Отправить</v-btn
      >
    </v-card-actions>
  </div>


</template>
<script>
import axios from "axios";
import { validationMixin } from "vuelidate";
import VueRecaptcha from "vue-recaptcha";
import { required, minLength, sameAs, email } from "vuelidate/lib/validators";
import { bus } from "../main";
export default {
  data() {
    return {
      sitekey: "6Le-imQaAAAAAC_Bgq1s2haE9A1IGTEL6YgLo2N2",
      grade: null,
      surname: "",
      name: "",
      patronymic: "",
      birth: "",
      snils: "",
      phone: "",
      email: "",
      currentSchool: "",
      terms1: false,
      terms2: false,
      terms3: false,
      grades: [6, 7, 8],
    };
  },
  components: { VueRecaptcha },
  name: "Login",
  props: {
    source: String,
  },

  methods: {
    capitalize(string) {
      return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    },
    submitEnrollee(recaptchaToken) {
      const enrollee = {
        grade: this.grade,
        surname: this.capitalize(this.surname),
        name: this.capitalize(this.name),
        patronymic: this.capitalize(this.patronymic),
        /* birth: this.birth, */
        snils: this.snils,
        /* phone: this.phone, */
        email: this.email.toLowerCase(),
        /* currentSchool: this.currentSchool, */
        recaptchaToken: recaptchaToken,
      };
      /* this.$emit("formSubmitted", data.data); */

      this.$axios({
        url: "/api/submit-enrollee/",
        data: enrollee,
        method: "POST",
      }).then((data) => {
        if (data.data.answer == "fail") {
          bus.$emit("snackbar", data.data.text);
          this.onCaptchaExpired();
        } else {
          bus.$emit("snackbar", data.data.text);
          this.$emit("formSubmitted");
          this.clearForm();
        }
      });
    },
    getProfiles(item) {
      this.$axios({
        url: "/api/profiles/" + "?nocache=" + new Date().getTime(),
        data: { grade: item },
        method: "POST",
      }).then((data) => {
        this.profiles = data.data.profiles;
      });
    },
    validate(event) {
      event.preventDefault();
      // тут можно добавить проверку на валидацию
      // например, с помощью vee validate
      // если с валидацией наших полей все хорошо, запускаем каптчу
      this.$refs.recaptcha.execute();
    },
    onCaptchaExpired() {
      this.$refs.recaptcha.reset();
    },
    clearForm() {
      (this.grade = null),
        (this.surname = ""),
        (this.name = ""),
        (this.patronymic = ""),
        (this.birth = ""),
        (this.snils = ""),
        (this.phone = ""),
        (this.email = ""),
        (this.currentSchool = "");
    },
  },
  created() {
    this.$axios.get("/api/profiles").then((a) => {
      /* this.grades = a.data.profileList; */
    });
    const $script = document.createElement("script");
    $script.async = true;
    $script.src =
      "https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit";
    document.head.appendChild($script);
  },
  computed: {
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("Неверный формат почты");
      !this.$v.email.required && errors.push("Обязательное поле");
      return errors;
    },
    surnameErrors() {
      const errors = [];
      if (!this.$v.surname.$dirty) return errors;
      !this.$v.surname.minLength && errors.push("Минимум 2 символа");
      !this.$v.surname.required && errors.push("Обязательное поле");
      return errors;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      !this.$v.name.minLength && errors.push("Минимум 2 символа");
      !this.$v.name.required && errors.push("Обязательное поле");
      return errors;
    },
    patronymicErrors() {
      const errors = [];
      if (!this.$v.patronymic.$dirty) return errors;
      !this.$v.patronymic.minLength && errors.push("Минимум 2 символа");
      !this.$v.patronymic.required && errors.push("Обязательное поле");
      return errors;
    },
    gradeErrors() {
      const errors = [];
      if (!this.$v.grade.$dirty) return errors;
      !this.$v.grade.required && errors.push("Обязательное поле");
      return errors;
    },

    snilsErrors() {
      const errors = [];
      if (!this.$v.snils.$dirty) return errors;
      !this.$v.snils.required && errors.push("Обязательное поле");
      !this.$v.snils.snilsVal && errors.push("Введите действущий СНИЛС");
      return errors;
    },
    birthErrors() {
      const errors = [];
      if (!this.$v.birth.$dirty) return errors;
      !this.$v.birth.required && errors.push("Обязательное поле");
      !this.$v.birth.birthVal &&
        errors.push("Введите дату рождения в формате дд.мм.гггг");
      return errors;
    },
    phoneErrors() {
      const errors = [];
      if (!this.$v.phone.$dirty) return errors;
      !this.$v.phone.required && errors.push("Обязательное поле");
      !this.$v.phone.phoneVal &&
        errors.push("Введите номер телефона в формате х-ххх-ххх-хх-хх");
      return errors;
    },
    checkboxErrors() {
      const errors = [];
      if (!this.$v.terms.$dirty) return errors;
      !this.$v.terms.sameAs && errors.push("Обязательное поле");
      return errors;
    },
    currentSchoolErrors() {
      const errors = [];
      if (!this.$v.currentSchool.$dirty) return errors;
      !this.$v.currentSchool.required && errors.push("Обязательное поле");
      return errors;
    },
    rulesErrors() {
      const errors = [];
      if (!this.$v.rules.$dirty) return errors;
      !this.$v.rules.required && errors.push("Обязательное поле");
      return errors;
    },
  },
  validations: {
    surname: { required, minLength: minLength(2) },
    name: { required, minLength: minLength(2) },
    patronymic: { required, minLength: minLength(2) },
    email: { required, email },
    grade: { required },
    currentSchool: { required },
    snils: {
      required,
      snilsVal: (val) => /^\d{3}-\d{3}-\d{3} \d{2}$/i.test(val),
    },
    birth: {
      required,
      birthVal: (val) =>
        /^\s*(3[01]|[12][0-9]|0?[1-9])\.(1[012]|0?[1-9])\.((?:19|20)\d{2})\s*$/i.test(
          val
        ),
    },
    phone: {
      required,
      phoneVal: (val) => /^(8-?|\+?7-?)?(\(?\d{3}\)?)-?(\d-?){6}\d$/i.test(val),
    },
    terms1: {
        sameAs: sameAs( () => true )
      },
    terms2: {
        sameAs: sameAs( () => true )
      },
    
    terms3: {
        sameAs: sameAs( () => true )
      }
  },
};
</script>


<style>
.mycard {
  background-color: white;
  padding: 15px;
}
.v-btn {
  letter-spacing: 0.02em;
  text-transform: none;
}
.v-label {
  font-size: 12px;
}
.card-text {
  padding-bottom: 0 !important;
}
@media (max-width: 768px) {
  .nopadding {
    padding: 0;
    padding-left: 12px;
    padding-right: 12px;
  }
}
</style>