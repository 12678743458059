<template>
    <div style="background-color: #f5f5f5; height: 100%">
        <div class="pa-5">
            <v-container class="my-5">
                <div class="logs">
                    <div v-for="(log, i) in logs" :key="i">
                           {{log}}
                    </div>
                    
                </div>
            </v-container>
        </div>
    </div>
</template>
<script>

export default {
    data() {
        return {
            logs: null
        }
    },
    mounted() {
       this.$axios.get('/api/logs',{}).then(info => {
            console.log(info)
            this.logs = info.data.split('\n')
        })
    },
    methods: {
        parseLogs(logs){
            const logEl = []
            logs.forEach(element => {
                // const dateLog = element.split(',')[0]
                // const time = element.split(',')[1].split(' ')
                // const timeLog = element.split(',')[1]
                // console.log(typeof timeLog)
                // console.log(element.split(','))
            });
        }
    }
}
</script>