<template>
	<div>
		<v-card  class="mx-auto px-5 py-5 mt-5"  min-width="30%" elevation="2"
			
			v-for="profile in profilesToShow"
			:key="profile._id"
			>
			
			<span class="font-weight-bold crisp">{{profile.grade}} класс</span>
			<br>
	
			<h3>{{profile.name}}</h3>
			
			<div class="mest"
				v-if="profile.quantity-profile.index > 0 ? false:true">
				<br>
				Мест больше нет
			</div>
			<div class="mest" v-if="profile.quantity - profile.index > 0 ? true:false">
				<br>
				Осталось мест:
				<span class="font-weight-bold">{{profile.quantity-profile.index+1}}</span>
			</div>
		</v-card>
	</div>
</template>

<script>
import axios from "axios";
export default {
	data() {
		return {
			profiles: []
		};
	},
	created() {
		this.$axios.get("/api/profiles", {}).then((res) => {
			this.profiles = res.data.profileList;
		});
	},
	computed: {
		profilesToShow: function () {
			return this.profiles.filter(function (profile) {
			return !profile.disabled
			})
		}
}
};
</script>
<style scoped>
.crisp {

    border-radius: 20px !important;;
    /* background-color: hotpink; */
    padding: 3px;
    /* border: 1px black; */
    padding-left: 10px;
    padding-right: 10px;
    border: 1px solid #bdcfe4;
}

</style>