<template>
    <div class="changer">
        <v-radio-group v-model="status" row>
            <v-radio label="Создан" value="created"></v-radio>
            <v-radio
                label="Необходимо подтвердить"
                value="confirmation-process"
            ></v-radio>
            <v-radio label="Подтвержден" value="confirmed"></v-radio>
            <v-radio label="Приглашен" value="invited"></v-radio>
            <v-radio label="Удален" value="deleted"></v-radio>
        </v-radio-group>
        <v-btn color="primary" class="success" @click="change">
            Изменить
        </v-btn>
    </div>
</template>

<script>
import { bus } from "../../main";

export default {
    data() {
        return {
            status: false,
        }
    },
    methods: {
        change() {
            this.$axios
                .post('/api/change-status-multiple', {
                    enrollees: this.$store.state.selected,
                    status: this.status,
                })
                .then((data) => {
                    if(data.status === 200) bus.$emit("snackbar", "Успешно изменено");
                    
                })
                .catch((err) => bus.$emit("snackbar", "Ошибка при изменении статуса"))
        },
    },
}
</script>

<style>
.changer {
    display: flex;
    flex-direction: column;
}
</style>
