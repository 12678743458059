<template>
  <div style="background-color: #f5f5f5; height: 100%">
    <div class="pa-5">
      <!-- <h3 class="subheading grey--text">Профили</h3> -->
      
      <div>
        <v-container class="my-5">
          <ExportAll/>
          <div class="mailingStatus" v-if="mailingStatus">
            Рассылка в процессе
          </div>
          <v-row>
            <!--  <transition-group name="flip-list"> -->
            <Profile
              v-for="profile in profiles"
              :profile="profile"
              :key="profile._id"
              @destroy="destroyProfile" 
            ></Profile>
            <!-- </transition-group> -->
          </v-row>
          <!--  <v-row>
                <v-col>da</v-col>
                <v-col>net</v-col>
            </v-row> -->
        </v-container>
      </div>
    </div>
  </div>
</template>
<script>
import Profile from "./Profile";
import axios from "axios";
import ExportAll from "./ExportAll"
export default {
  components: {
    Profile,
    ExportAll
  },
  data() {
    return {
      profiles: [],
      mailingStatus: false
    };
  },
  created() {
    this.$axios.post("/api/profiles-admin", {}).then((res) => {
      this.profiles = res.data.profiles;
      this.mailingStatus= this.profiles.some(this.checkMailsingStatus)
    });
  },
  methods: {
    destroyProfile(_id) {
      for (var i = 0; i < this.profiles.length; i++)
        if (this.profiles[i]._id === _id) {
          this.profiles.splice(i, 1);
          break;
        }
    },
    checkMailsingStatus(profile) {
      return profile.sendMails == true
    }
  },
};
</script>
<style scoped>
.mailingStatus {
  text-align: center;
  padding: 5px;
  border-radius: 5px;
  background-color: rgb(255, 196, 0);
  color: white;
  margin: 20px auto;
}
</style>