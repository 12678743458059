<template>
    <transition name="fade">
        <div id="myModal" class="modal" v-if="snackbar" :timeout="timeout">
            <!-- Modal content -->
            <div class="modal-content" @click="closeModal">
                {{ text }}
                <v-card-actions>
                    <v-btn depressed class="close mt-6">Понятно</v-btn>
                </v-card-actions>
            </div>
        </div>
    </transition>
</template>
<script>
import { bus } from '../main'
export default {
    data: () => ({
        snackbar: false,
        text: 'My timeout is set to 2000.',
        timeout: 700000,
    }),
    props: {
        msg: {
            type: String,
            default() {
                return ''
            },
        },
    },
    methods: {
        closeModal() {
            this.snackbar = false
        },
    },
    created() {
        bus.$on('snackbar', (data) => {
            this.text = data
            this.snackbar = true
        })
    },
}
</script>
<style scoped>
.modal {
    position: fixed; /* Stay in place */
    z-index: 1000; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4);
}
/* Modal Content/Box */
.modal-content {
    background-color: #333333;
    color: rgba(255, 255, 255, 0.87);
    margin: 15% auto; /* 15% from the top and centered */
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    border-radius: 4px; /* Could be more or less, depending on screen size */
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
    opacity: 0;
}
</style>