<template>
    <span class="enrolleeId">{{ enrolleeId }}</span>
</template>

<script>
export default {
    props: {
        enrolleeId: String
    },
}
</script>

<style lang="scss" scoped>
.enrolleeId {
    background-color: #1976d2;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    color: white;
    border-radius: 4px;
}
</style>
