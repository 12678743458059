<template>
<div class="mycard">
	<v-card-text class="card-text">
	Подтверждение регистрации
	<v-form @submit.prevent="validate" novalidate>
		<v-text-field
		v-mask="'###-###-### ##'"
		v-model="snils"
		label="СНИЛС"
		:error-messages="snilsErrors"
		required
		@input="$v.snils.$touch()"
		@blur="$v.snils.$touch()"
		prepend-icon="mdi-badge-account-horizontal"
		></v-text-field>
		<v-text-field
		v-mask="'##.##.####'"
		v-model="birth"
		label="Дата рождения"
		:error-messages="birthErrors"
		required
		@input="$v.birth.$touch()"
		@blur="$v.birth.$touch()"
		prepend-icon="mdi-calendar-range"
		></v-text-field>
	</v-form>
	</v-card-text>
	<v-card-actions>
	<v-spacer></v-spacer>
	<vue-recaptcha
		ref="recaptcha"
		size="invisible"
		:sitekey="sitekey"
		@verify="confirmEnrollee"
		@expired="onCaptchaExpired"
	/>
	<v-btn
		type="submit"
		color="success"
		@click="validate"
		:disabled="$v.$invalid"
		>Отправить</v-btn
	>
	</v-card-actions>
</div>

<!--  </v-flex> -->
</template>
<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";
import { bus } from "../main";
import VueRecaptcha from "vue-recaptcha";
export default {
	components: {
		VueRecaptcha
	},
	data() {
		return {
		birth: "",
		snils: "",
		sitekey: "6Le-imQaAAAAAC_Bgq1s2haE9A1IGTEL6YgLo2N2",
		};
	},
	methods: {
		async confirmEnrollee(recaptchaToken) {
			const enrollee = {
				birth: this.birth,
				snils: this.snils,
				recaptchaToken: recaptchaToken,
			};
			const answer = await axios({
				url: "/api/confirm-enrollee/",
				data: enrollee,
				method: "POST",
			})
			if (answer.data.answer == "fail") {
				bus.$emit("snackbar", answer.data.text);
			} else {
				bus.$emit("snackbar", answer.data.text);
			}
		},
		validate(event) {
		event.preventDefault();
		// тут можно добавить проверку на валидацию
		// например, с помощью vee validate
		// если с валидацией наших полей все хорошо, запускаем каптчу
		//this.$refs.recaptcha.execute();

		this.$refs.recaptcha.execute();
		},
		onCaptchaExpired() {
		this.$refs.recaptcha.reset();
		},
	},
	created(){
		const $script = document.createElement("script");
		$script.async = true;
		$script.src =
		"https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit";
		document.head.appendChild($script);
	},
	computed: {
		snilsErrors() {
		const errors = [];
		if (!this.$v.snils.$dirty) return errors;
		!this.$v.snils.required && errors.push("Обязательное поле");
		!this.$v.snils.snilsVal && errors.push("Введите действущий СНИЛС");
		return errors;
		},
		birthErrors() {
		const errors = [];
		if (!this.$v.birth.$dirty) return errors;
		!this.$v.birth.required && errors.push("Обязательное поле");
		!this.$v.birth.birthVal &&
			errors.push("Введите дату рождения в формате дд.мм.гггг");
		return errors;
		},
	},
	validations: {
		snils: {
		required,
		snilsVal: (val) => /^\d{3}-\d{3}-\d{3} \d{2}$/i.test(val),
		},
		birth: {
		required,
		birthVal: (val) =>
			/^\s*(3[01]|[12][0-9]|0?[1-9])\.(1[012]|0?[1-9])\.((?:19|20)\d{2})\s*$/i.test(
			val
			),
		},
	},
};
</script>