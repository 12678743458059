/* import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import( "../views/About.vue")
  }
];

const router = new VueRouter({
  routes
});

export default router; */



import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '/src/store/index.js'
/* import About from '/src/components/About.vue'; */
import Dashboard from '/src/views/Dashboard.vue';
import Login from '/src/views/Login.vue';
import Home from '/src/views/Home.vue';
import Confirm from '/src/views/Confirm.vue';
import EnrolleeList from '/src/components/EnrolleeList.vue'
import ProfileList from '/src/components/ProfileList.vue'
import Mails from '/src/components/Mails.vue'
import Submit from "/src/components/Submit";
import Logs from '/src/components/Logs'
import Closed from "/src/views/Closed";
import AddSecret from "/src/views/AddSecret"

Vue.use(VueRouter)

const router = new VueRouter({
    mode: "history",
    base: __dirname,
    routes: [
        /*   { path: "/", component: Home },
          { path: "/home", component: Home },
          { path: "/about", component: About },
          { path: "/dashboard", component: Dashboard, meta: { requiresAuth: true } },
          { path: "/login", component: Login }, */
        {
            path: '/',
            component: Home,

        },
        {
            path: '/submit',
            component: Submit,

        },
        {
            path: '/confirm',
            component: Confirm,

        }
        ,
        {
            path: '/home',
            component: Login,

        },
/*         {
            path: '/secrets',
            component: AddSecret,

        }, */
        
        {
            path: '/login',
            component: Login
        },
        {
            path: '/dashboard',
            component: Dashboard,
            children: [
                {
                    path: '/dashboard/enrollee-list',
                    component: EnrolleeList
                },
                {
                    path: '/dashboard/profile',
                    component: ProfileList
                },
                {
                    path: '/dashboard/mails',
                    component: Mails
                },
                {
                    path: '/dashboard/logs',
                    component: Logs
                }
            ]
        },
        {
            path: '/:pathMatch(.*)*',
            component: Home,

        }
    ],
});


router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters.isLoggedIn) {
            next()
            return
        }
        next('/login')
    } else {
        next()
    }
})
export default router
