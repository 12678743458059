<template>
<div class="layout">
	<!-- <div class="background_school"></div> -->
	<div class="gradient-bg" style="width: 100%">
	<v-container class="myContainer">
		<div class="d-flex flex-wrap">
		<div class="px-5 py-5">
			<span class="text">Школа №1535</span>
			<img class="logo icon" :src="images.sample" height="30px" />
		</div>
		</div>

		<div class="d-flex flex-wrap justify-space-around mt-5">
		<v-dialog width="1100" v-model="dialog">
			<template v-slot:activator="{ on }">
			<v-btn large text class="d-flex flex-wrap main-btn mt-1" v-on="on"
				>Зарегистрироваться</v-btn
			>
			</template>
			<Submit @formSubmitted="closeForm"></Submit>
		</v-dialog>
		<v-dialog width="700" v-model="dialog1">
			<template v-slot:activator="{ on }">
			<v-btn large text class="d-flex flex-wrap main-btn mt-1" v-on="on"
				>Проверить регистрацию</v-btn
			>
			</template>
			<CheckStatus @formSubmitted="closeForm" />
		</v-dialog>
		<!-- <Snackbar /> -->
		<MySnackbar/>
		</div>
		<v-divider dark class="mt-10"></v-divider>
		<div class="mt-5">
		<h1>Информация</h1>
		</div>
		<v-card class="mx-auto px-5 py-5 mt-5" min-width="30%" elevation="2">
			<span v-html="information"></span>
		</v-card>

		<div class="mt-5">
		<h1>Количество мест</h1>
		</div>

		<ProfileCard />
	</v-container>
	<div class="footer">
		<div class="container d-flex flex-wrap justify-space-around mt-5 grey--text">
			<span class="px-3 py-3">По всем вопросам: orbita@liceum1535.ru</span>
		</div>
	</div>
	</div>
</div>
</template>
<script>
import Submit from "../components/Submit";
import Snackbar from "../components/Snackbar";
import MySnackbar from "../components/mySnackbar.vue"
import CheckStatus from "../components/CheckStatus";
import ProfileCard from "../components/home/profileCard.vue";
import axios from 'axios';

export default {
	metaInfo: {
		title: "Школа №1535. Регистрация на экзамены",
		htmlAttrs: {
		lang: "ru",
		amp: true,
		},
	},
	components: {
		Submit,
		Snackbar,
		CheckStatus,
		ProfileCard,
		MySnackbar
	},
	data() {
		return {
		dialog: false,
		dialog1: false,
		images: {
			sample: require("/src/assets/logo_1535.svg"),
		},
		information: ''
		};
	},
	methods: {
		closeForm() {
		this.dialog = false;
		this.dialog1 = false;
		},
	},
	created(){
		this.$axios.get("/api/information", {}).then((res) => {
      		if (res.data.answer == 'success') {
				  this.information = res.data.information
			  }
    	});
	}
};
</script>
<style>
.myContainer{
	max-width: 1000px;
}
.background_school {
background-image: url("../assets/school-min.jpg");
height: 100%;
min-width: 10%;
}
.layout {
height: 100%;
display: flex;
}
.s123 {
display: block;
margin-left: auto;
margin-right: auto;
width: 50%;
}
.main-btn {
background-color: white !important;
    color: black;
    box-shadow: 1px 3px 3px 0px rgb(0 0 255 / 20%);
}
.main-btn:hover {
background-color: white !important;
}
.icon,
.text {
	text-transform: uppercase;
	vertical-align: middle;
	display: inline-block;
	font-weight: 300 !important;
	font-size: 35px;
}
.icon {
    margin-left: 15px;
}
.footer {
	background-color: white;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
</style>