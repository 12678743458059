<template>
    <div class="text-center">
        <v-dialog width="900" v-model="dialog">
            <template v-slot:activator="{ on }">
                <v-btn text color="primary" min-width="80%" class="my-2" v-on="on"
                    >Изменить</v-btn
                >
            </template>
            <v-card class="px-3 py-5">
                <v-card-title class="headline" primary-title
                    >Настройки профиля {{profile.fullName}} </v-card-title
                >
                <v-card-text>
                    <div class="subprofiles"
                        v-if="profile.subprofiles"
                    >
                        Подпрофили:
                    <div class="subprofilesControl">
                        <v-layout row wrap>
                            <v-checkbox v-for="subprofile in allSubprofiles" class="pl-3"
                        :key="subprofile" 
                        :label="subprofile"
                        :value="subprofile"
                        v-model="subprofilesEnabled"
                        @change="updateSubprofiles()"
                        ></v-checkbox>
                            </v-layout>
                        
                        
                    </div>
                        
                    </div>
                </v-card-text>
                <!-- <v-card-actions>
                    <v-btn
                    
                    >Сохранить</v-btn> 
                </v-card-actions> -->
                   
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import axios from 'axios';
export default {
    data() {
        return {
            dialog: false,
            allSubprofiles: [],
            subprofilesEnabled: []
        }
    },
    props: ['profile'],
    methods: {
        async updateSubprofiles() {
            const updateStatus = await axios.post('/api/updateSubprofiles', {profileId:this.profile._id, selectedSubprofiles:this.subprofilesEnabled})
        }
    },
    created() {
        this.allSubprofiles = this.profile.subprofiles
        this.subprofilesEnabled = this.profile.subprofilesEnabled
    }

}
</script>