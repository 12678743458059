<template>
    <v-col md="6"
        ><v-card
            :loading="profile.sendMails"
            flat
            :key="profile._id"
            class="mt-3"
        >
            <v-card class="mx-auto pa-3">
                <v-row>
                    <v-col cols="8">
                        <v-card-text>
                            <p class="display-1 text--primary">
                                {{ profile.fullName }}
                            </p>
                            <p></p>
                            <div class="text--primary">
                                Количество мест для регистрации:
                                {{ profile.quantity }}
                            </div>
                            <div class="text--primary">
                                Количество зарегистрированных:
                                {{ profile.index - 1 }}
                            </div>
                        </v-card-text>
                    </v-col>
                    <v-card-actions class="flex px-5 justify-center">
                        <v-btn-toggle>
                            <v-btn
                                :disabled="sending"
                                @click="
                                    sendMails(newList._id, profile, 'confirm')
                                "
                                >Подтверждение<v-icon right small
                                    >mdi-check-circle-outline</v-icon
                                ></v-btn
                            >
                            <v-btn
                                :disabled="sending"
                                @click="
                                    sendMails(newList._id, profile, 'invite')
                                "
                                >Приглашение<v-icon small right
                                    >mdi-cake</v-icon
                                ></v-btn
                            >
                            <v-btn
                                :disabled="sending"
                                @click="downloadList(newList._id)"
                                >Выгрузить<v-icon right small
                                    >mdi-file-download</v-icon
                                ></v-btn
                            >
                        </v-btn-toggle>
                    </v-card-actions>

                </v-row>
                <!-- <canvas ref='canvas'></canvas> -->
                <v-card-actions class="flex justify-space-between">
                    <ProfileSettings :profile="newList"/>
                    <v-switch
                        label="Заблокировать"
                        @click="changeState(newList._id, newList)"
                        v-model="newList.disabled"
                    ></v-switch>
                    <div>
                        <v-btn color="error" dark @click="doDelete" text>
                            Удалить
                        </v-btn>
                        <confirm-dialogue
                            ref="confirmDialogue"
                        ></confirm-dialogue>
                    </div>
                </v-card-actions>
            </v-card> </v-card
    ></v-col>
</template>
<script>
import axios from 'axios'
import { bus } from '../main'

import ConfirmDialogue from './ConfirmDialogue.vue'
import ProfileSettings from './profileSettings.vue'
export default {
    data() {
        return {
            myval: 'yes',
            switch1: true,
            profiles: {},
            profileToDelete: '',
            dialog: false,
            sending: false,
        }
    },
    components: {
        ConfirmDialogue,
        ProfileSettings
        /* EditEnrollee, */
    },
    /*  props: ["profile"], */
    watch: {
        switch1(newValue) {
            //called whenever switch1 changes

        },
    },
    props: {
        profile: {
            type: Object,
            default() {
                return {}
            },
        },
    },
    computed: {
        newList: function () {
            return (this.profiles = Object.assign(this.profiles, this.profile))
        },
        chartData: function () {
            return []
        },
    },
    methods: {
        async doDelete() {
            const ok = await this.$refs.confirmDialogue.show({
                message: 'Вы уверены, что хотите удалить профиль?',
                okButton: 'Удалить',
            })
            // If you throw an error, the method will terminate here unless you surround it wil try/catch
            if (ok) {
                this.$axios
                    .post('/api/delete-profile/', {
                        _id: this.newList._id,
                    })
                    .then((data) => {
                        bus.$emit('snackbar', data.data.msg)
                        this.$emit('destroy', this.newList._id)
                    })
            }
        },
        async sendMails(id, profile, type) {
            const ok = await this.$refs.confirmDialogue.show({
                message: 'Вы уверены, что хотите начать рассылку?',
                okButton: 'Начать',
            })
            // If you throw an error, the method will terminate here unless you surround it wil try/catch
            if (ok) {
                this.sending = true
                profile.sendMails = true
                bus.$emit("snackbar", 'Рассылка начата');
                const answer = await this.$axios
                    .post('/api/send-mails/', {
                        _id: id,
                        type: type,
                    })
                bus.$emit("snackbar", answer.data.answer);
            }
        },
        changeState(id, newList) {
            this.$axios
                .post('/api/edit-profile/', {
                    _id: id,
                    disabled: newList.disabled,
                })
                .then((data) => {
                })
            // Do stuff
        },
        async downloadList(id) {
            const ok = await this.$refs.confirmDialogue.show({
                message: 'Вы уверены, что хотите выгрузить данные по профилю?',
                okButton: 'Начать',
            })
            if (ok) {
                this.$axios
                    .post(
                        '/api/downloadList/',
                        {
                            _id: id,
                        },
                        { responseType: 'arraybuffer' }
                    )
                    .then((data) => {
                        var fileURL = window.URL.createObjectURL(
                            new Blob([data.data], {
                                type: 'vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
                            })
                        )
                        var fileLink = document.createElement('a')
                        fileLink.href = fileURL
                        fileLink.setAttribute(
                            'download',
                            `${id}-${new Date().toLocaleString('ru-RU')}.xlsx`
                        )
                        document.body.appendChild(fileLink)
                        fileLink.click()
                        bus.$emit("snackbar", 'Файл загружается');
                    })
            }
        },
    },
}
</script>
<style>
.v-input__slot .v-label {
    color: black !important;
}
</style>