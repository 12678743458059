<template>
    <div>
        <v-card flat :key="user._id" class="mt-1">
            <v-layout
                wrap
                :class="`pa-1 users ${enrollee.confirmation} ${enrollee.deleted}`"
            >
                <v-col md="3">
                    <v-row>
                        <v-col :cols="10">
                            <div class="caption grey--text">ФИО</div>
                            <EditEnrollee
                                @changesSaved="changesSaveds"
                                :element="user"
                            />
                        </v-col>
                    </v-row>
                </v-col>
                <v-col xs="1">
                    <div class="">
                        <div class="caption grey--text">Номер</div>
                        <div :class="{ selected: isSelected, id: true, selectable: true }" v-on:click='mutateList'>{{ enrollee.enrolleeId }}</div>
                    </div>
                </v-col>

                <v-col xs="3">
                    <div class="">
                        <div class="caption grey--text">e-mail</div>
                        <div>{{ enrollee.email }}</div>
                    </div>
                </v-col>
                <v-col md="3">
                    <div class="text-right">
                        <div class="caption grey--text">Статус</div>
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-chip
                                    v-bind="attrs"
                                    v-on="on"
                                    small
                                    :class="`chip ${enrollee.status} ${enrollee.deleted} white--text caption my-2 outlined label`"
                                >
                                    {{ enrollee.comment }}</v-chip
                                >
                            </template>
                            <span>{{ enrollee.dateNow }}</span>
                        </v-tooltip>
                    </div>
                </v-col>
            </v-layout>
        </v-card>
    </div>
</template>
<script>
import EditEnrollee from './EditEnrollee'
export default {
    data() {
        return {
            enrollee: this.user,
        }
    },
    components: {
        EditEnrollee,
    },
    props: ['user'],
    methods: {
        mutateList: function() {
            this.$store.commit('changeSelected', this.enrollee.enrolleeId)
        },
        changesSaveds(data) {
            this.enrollee = data
        },

    },
    computed: {
        isSelected: function() {
            return this.$store.state.selected.includes(this.enrollee.enrolleeId)
        }
    }
}
</script>
<style scoped>
.id {
    cursor: pointer;
}
.selected {
    font-weight: 600;
    color: #1976d2;
}
.selectable {
    border-radius: 4px;
    transition: 0.2s;

}
.selectable:hover {
    background-color: rgb(235, 235, 235);
}
</style>