<template>
    <popup-modal ref="popup">
        <v-card class="px-3 py-3">
            <v-card-text class="">
                {{ message }}
            </v-card-text>
            <v-card-actions class="flex justify-space-between px-3">
                <v-btn text @click="_cancel">{{ cancelButton }}</v-btn>
                <v-btn
                    color="primary"
                    text
                    @click="_confirm"
                >
                    {{ okButton }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </popup-modal>
</template>
<script>
import PopupModal from './PopupModal.vue'
export default {
    name: 'ConfirmDialogue',
    components: { PopupModal },
    data: () => ({
        // Parameters that change depending on the type of dialogue
        message: undefined, // Main text content
        okButton: undefined, // Text for confirm button; leave it empty because we don't know what we're using it for
        cancelButton: 'Отмена', // text for cancel button

        // Private variables
        resolvePromise: undefined,
        rejectPromise: undefined,
    }),
    methods: {
        show(opts = {}) {
            this.message = opts.message
            this.okButton = opts.okButton
            if (opts.cancelButton) {
                this.cancelButton = opts.cancelButton
            }
            // Once we set our config, we tell the popup modal to open
            this.$refs.popup.open()
            // Return promise so the caller can get results
            return new Promise((resolve, reject) => {
                this.resolvePromise = resolve
                this.rejectPromise = reject
            })
        },
        _confirm() {
            this.$refs.popup.close()
            this.resolvePromise(true)
        },
        _cancel() {
            this.$refs.popup.close()
            this.resolvePromise(false)
            // Or you can throw an error
            // this.rejectPromise(new Error('User cancelled the dialogue'))
        },
    }
}
</script>
<style>
</style>