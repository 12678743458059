<template>
  <div style="background-color: #f5f5f5; height: 100%">
    <div class="pa-5">
      <!-- <h3 class="subheading grey--text">Регистрации</h3> -->
      <div>
        <v-container class="my-5">
          <span
            class="crisp"
            v-for="profile in profiles"
            :key="profile._id"
            @click="showEnrolleeList(profile)"
          >
            {{ profile.fullName }}
          </span>
          <v-text-field v-model="query" label="поиск" />
          <div id="staggered-list-demo"></div>
          <v-layout class="mb-3">
            <v-btn small text @click="sortBy('personalNumber')">
              <v-icon small left>mdi-view-stream</v-icon>
              <span class="caption text-lowercase">по id</span>
            </v-btn>
            <v-btn small text @click="sortBy('surname')">
              <v-icon small left>mdi-account</v-icon>
              <span class="caption text-lowercase">по ФИО</span>
            </v-btn>
          </v-layout>

          <!-- <transition-group name="list-item"> -->
            <Enrollee
              v-for="user in computedList"
              :user="user"
              :key="user._id"
              class=""
            />
          <!-- </transition-group> -->
        </v-container>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Enrollee from "./Enrollee.vue";
export default {
  data() {
    return {
      familia: "keko",
      users: [],
      profiles: [],
      query: "",
    };
  },

  components: {
    Enrollee,
  },
  methods: {
    sortBy(prop) {
      this.users.sort((a, b) => (a[prop] < b[prop] ? -1 : 1));
    },
    showEnrolleeList(profile) {
      this.query = ''
      axios
        .get("/api/registries", {
          params: {
            profile: profile.enrolleePrefix,
          },
        })
        .then((res) => {
          // console.log(res.data.users)
          this.users = res.data.users;
        });
    },
  },
  created() {
   /*  axios.get("/api/registries", {}).then((res) => {
      this.users = res.data.users;
    }); */
    axios.get("/api/profiles", {}).then((res) => {
      res.data.profileList.unshift({fullName: 'Все', _id:1})
      this.profiles = res.data.profileList;
    });
  },
  computed: {
    computedList: function () {
      var vm = this;
      return this.users.filter(function (item) {
        return (
          item.surname.toLowerCase().indexOf(vm.query.toLowerCase()) !== -1 || item.name.toLowerCase().indexOf(vm.query.toLowerCase()) !== -1|| item.email.toLowerCase().indexOf(vm.query.toLowerCase()) !== -1|| item.enrolleeId.toLowerCase().indexOf(vm.query.toLowerCase()) !== -1
        );
      });
    },
  },
};
</script>
<style>
.flip-list-move {
  transition: transform 1s;
}
.users.confirmed {
  border-left: 4px solid #4c9c00;
}
.confirmation-process {
  background-color: #cfc203;
}

.theme--light.v-chip.confirmed:not(.v-chip--active) {
  background: #4c9c00;
}

.users.deleted {
  border-left: 4px solid #9c0000;
}

.theme--light.v-chip.deleted:not(.v-chip--active) {
  background: #9c0000;
}
.v-btn.deleted {
  background: #9c0000;
}


.theme--light.v-chip.confirmation-process:not(.v-chip--active) {
  background: #cfc203;
}
.v-btn.confirmation-process  {
  background: #cfc203;
}

.theme--light.v-chip.invited:not(.v-chip--active) {
  background: #2196F3;
}
.v-btn.invited  {
  background: #2196F3;
}


.crisp {
  display: inline-block;
  border-radius: 20px !important;
  /* background-color: hotpink; */
  padding: 3px;
  /* border: 1px black; */
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid #bdcfe4;
  margin: 3px;
  cursor: pointer;
  font-size: 13px;
  transition: background-color 0.3s ease-out;
}
.crisp:hover {
  background-color: rgb(255, 255, 255);
}
</style>