<template>
    <div class="multiselect-popup">
        <div @click="closeModal" class="close">
            <v-icon small left>mdi-close</v-icon>
        </div>
        <List />
        <StatusChanger />
    </div>
</template>

<script>
import List from './Mulitselect/List.vue'
import StatusChanger from './Mulitselect/StatusChanger.vue'
export default {

    components: { List, StatusChanger },
    methods: {
        closeModal: function () {
            this.$store.commit('closeModal')
        }
    },
}
</script>
<style lang="scss" scoped>
.multiselect-popup {
    transition: 0.2s;
    &:hover {
        opacity: 1;
    }
    width: 400px;
    height: calc(100% - 20px);
    height: calc(100vh - 20px);
    position: fixed;
    right: 10px;
    top: 10px;
    border: 1px solid rgb(226, 226, 226);
    background-color: rgb(255, 255, 255);
    padding: 30px;
    box-sizing: border-box;
    z-index: 999;
    border-radius: 5px;
    flex-direction: column;
    display: flex;
    justify-content: space-between;
    opacity: 0.7;
    .close {
        cursor: pointer;
        position: absolute;
        right: 10px;
        top: 10px;
    }
}
</style>
