<template>
  <v-container
    class="gradient-bg"
    fluid
    fill-height
    align="center"
  >
    <div class="row">
      <v-card class="mx-auto px-5 py-5" min-width="30%" elevation="2">
        <transition name="fade" mode="out-in" appear>
          <div class="item" key="1" v-if="!showSignUp">
            <form key="12" @submit.prevent="validate" novalidate>
              <h1>Вход</h1>
              <v-text-field
                v-model="form.username"
                :error-messages="usernameErrors"
                label="Пользователь"
                required
                @input="$v.form.username.$touch()"
                @blur="$v.form.username.$touch()"
                prepend-icon="mdi-account"
              />
              <v-text-field
                v-model="form.password"
                :type="showPassword ? 'text' : 'password'"
                :error-messages="passwordErrors"
                label="Пароль"
                required
                @input="$v.form.password.$touch()"
                @blur="$v.form.password.$touch()"
                prepend-icon="mdi-lock"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPassword = !showPassword"
              />
              <vue-recaptcha
                ref="recaptcha"
                size="invisible"
                :sitekey="sitekey"
                @verify="login"
                @expired="onCaptchaExpired"
              />
              <v-btn
                class="mt-3"
                type="submit"
                color="primary"
                :disabled="
                  $v.form.username.$invalid || $v.form.password.$invalid
                "
              >
                Войти
              </v-btn>
              <v-btn class="mx-3 mt-3" @click="showSignUp = true" text>
                Регистрация
              </v-btn>
            </form>
          </div>

          <div class="item" key="2" v-if="showSignUp">
            <form key="13" @submit="validate" novalidate>
              <h1>Регистрация</h1>
              <v-text-field
                v-model="form.username"
                :error-messages="usernameErrors"
                label="Пользователь"
                required
                @input="$v.form.username.$touch()"
                @blur="$v.form.username.$touch()"
                prepend-icon="mdi-account"
              />
              <v-text-field
                v-model="form.email"
                :error-messages="emailErrors"
                label="Почта"
                required
                @input="$v.form.email.$touch()"
                @blur="$v.form.email.$touch()"
                prepend-icon="mdi-mail"
              />
              <v-text-field
                v-model="form.password"
                :type="showPassword ? 'text' : 'password'"
                :error-messages="passwordErrors"
                label="Пароль"
                required
                @input="$v.form.password.$touch()"
                @blur="$v.form.password.$touch()"
                prepend-icon="mdi-lock"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPassword = !showPassword"
              />
              <v-text-field
                v-model="form.confirmPassword"
                :type="showPassword ? 'text' : 'password'"
                :error-messages="confirmPasswordErrors"
                label="Повторите пароль"
                required
                @input="$v.form.confirmPassword.$touch()"
                @blur="$v.form.confirmPassword.$touch()"
                prepend-icon="mdi-lock"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPassword = !showPassword"
              />
              <vue-recaptcha
                ref="recaptcha"
                size="invisible"
                :sitekey="sitekey"
                @verify="register"
                @expired="onCaptchaExpired"
              />
              <v-btn
                class="mt-3"
                type="submit"
                depressed
                color="primary"
                disabled
              >
                Зарегистрироваться
              </v-btn>
              <v-btn class="mx-3 mt-3" @click="showSignUp = false" text>
                Вход
              </v-btn>
            </form>
          </div>
        </transition>
      </v-card>
    </div>
    <Snackbar />
  </v-container>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minLength, sameAs, email } from "vuelidate/lib/validators";
import VueRecaptcha from "vue-recaptcha";
import axios from "axios";
import Snackbar from "../components/Snackbar";
import { bus } from "../main";

export default {
  mixins: [validationMixin],
  name: "Register",
  components: { VueRecaptcha, Snackbar },
  data() {
    return {
      sitekey: "6Le-imQaAAAAAC_Bgq1s2haE9A1IGTEL6YgLo2N2",
      form: {
        email: "",
        username: "",
        password: "",
        confirmPassword: "",
      },
      showSignUp: false,
      show: true,
      showPassword: false,
    };
  },
  methods: {
    async login(recaptchaToken) {
      let username = this.form.username;
      let password = this.form.password;
      const user = {
        username: username,
        password: password
      }
      const answer = await this.$axios({ url: "/login", data: user, method: "POST" })
      if (answer.data.success == false) {
          this.$refs.recaptcha.reset();
          bus.$emit("snackbar", "Неверное имя пользователь или пароль");
      } else {
        const token = answer.data.token;
        const user = answer.data.user;
        localStorage.setItem("token", token);
        localStorage.setItem("user", user);
        axios.defaults.headers.common["Authorization"] = token;
        this.$router.push("/dashboard") 
      }
    },
    register(recaptchaToken) {
      this.$axios
        .post("signup", {
          email: this.form.email,
          username: this.form.username,
          password: this.form.password,
          recaptchaToken: recaptchaToken,
        })
        .then((res) => {
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("user", res.data.user);
          if (res.data.success == true) {
            this.makeToast(
              "success",
              "Success",
              "You have successfully registered. The information has been sent to your email."
            );
            /*             setTimeout(() => {
              this.$router.push("/");
            }, 3000); */
          } else {
            this.makeToast(
              "danger",
              "Oops",
              res.data.message + ` ` + res.data.err.message
            );
            this.onCaptchaExpired();
          }
        });
    },
    validate(event) {
      event.preventDefault();
      // тут можно добавить проверку на валидацию
      // например, с помощью vee validate
      // если с валидацией наших полей все хорошо, запускаем каптчу
      this.$refs.recaptcha.execute();
    },

    onCaptchaExpired() {
      this.$refs.recaptcha.reset();
    },
    makeToast(variant, title, msg) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        toaster: "b-toaster-top-center",
        solid: true,
      });
    },
  },
  validations: {
    form: {
      username: { required, minLength: minLength(4) },
      email: { required, email },
      password: { required, minLength: minLength(6) },
      confirmPassword: { sameAsPassword: sameAs("password") },
    },
  },

  created() {
    const $script = document.createElement("script");
    $script.async = true;
    $script.src =
      "https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit";
    document.head.appendChild($script);
  },
  computed: {
    usernameErrors() {
      const errors = [];
      if (!this.$v.form.username.$dirty) return errors;
      !this.$v.form.username.minLength && errors.push("Минимум 4 символа");
      !this.$v.form.username.required &&
        errors.push("Введите имя пользователя");
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.form.email.$dirty) return errors;
      !this.$v.form.email.email && errors.push("Введите действительный e-mail");
      !this.$v.form.email.required && errors.push("Введите e-mail");
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.form.password.$dirty) return errors;
      !this.$v.form.password.minLength &&
        errors.push("Пароль должен содержать минимум 6 символов");
      !this.$v.form.password.required && errors.push("Введите пароль");
      return errors;
    },
    confirmPasswordErrors() {
      const errors = [];
      if (!this.$v.form.confirmPassword.$dirty) return errors;
      !this.$v.form.confirmPassword.sameAsPassword &&
        errors.push("Пароли должны совпадать");
      return errors;
    },
  },
};
</script>

<style>
.fade-enter-active {
  transition: opacity 0.5s;
}
.fade-enter {
  opacity: 0;
}

.imageBackground {
  height: 100vh;
  /*   background-color: brown;
 */
  background: url("/img/wheel_cogs.jpg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.no-margin {
  margin: 0 !important;
}
.cursorPointer {
  cursor: pointer;
}
@media only screen and (max-width: 1200px) {
  .hideMobile {
    /* display: none; */
    height: 15vh;
    /*     background: url("/img/wheel_cogs.jpg") no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover; */
  }
}
.gradient-bg {
  background: #f0f5fa;
}
/* .gradient-bg {
  background: rgb(81, 58, 180);
  background: linear-gradient(
    90deg,
    rgba(81, 58, 180, 1) 0%,
    rgba(253, 29, 29, 0.876721635919993) 50%,
    rgba(252, 176, 69, 1) 100%
  );
} */
</style>
