<template>
    <div class="wrapper">
        <div class="msg">
            <div class="px-5 py-5 header">
			<span class="text">Школа №1535</span>
			<img class="logo icon" :src="images.sample" height="30px" />
		    </div>
            <h1>Мы обновляемся, но скоро обязательно вернемся.</h1>
            <img src="" alt="">
        </div>
        
    </div>
</template>
<script>
export default {
    metaInfo: {
        title: 'Школа №1535. Регистрации.',
        htmlAttrs: {
            lang: 'ru',
            amp: true,
        },
    },
    data() {
        return {
            images: {
			sample: require("/src/assets/logo_1535.svg"),
		}
        }
    },
}
</script>
<style scoped>
.wrapper {
    display: flex;
    height: 100%;
    justify-content: center;
    margin-top: 20%;
}
h1 {
    text-align: center;
}
.msg {
    padding: 15px;
}
.header {
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>