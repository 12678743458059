<template>
  <div class="text-center">
    <v-dialog width="800" v-model="dialog">
      <template v-slot:activator="{ on }">
        <v-btn color="primary" min-width='80%' v-on="on">Заглушка</v-btn>
      </template>
      <v-card>
        <v-card-title class="headline" primary-title
          >Информация</v-card-title
        >
        <v-card-text>
          <v-form class="px-3">
			<div>
				<vue-editor v-model="html"></vue-editor>
				 <div class="d-flex justify-space-between"> <v-btn text class="mx-0 mt-3" @click="dialog = false">Закрыть</v-btn>
            <v-btn text class="success mx-0 mt-3" @click="submit">Сохранить</v-btn></div>
			</div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { bus } from "../main";
import axios from "axios";
import { VueEditor } from "vue2-editor";
export default {
	components:
		{VueEditor},
	data() {
		return {
		html: "",
		dialog: false
		};
	},
	methods: {
		submit() {
		this.$axios({
			url: "/api/information",
			data: {html: this.html},
			method: "POST",
		}).then((data) => {
			bus.$emit("snackbar", data.data.text);
		});
		},
	},
	created() {
		this.$axios.get("/api/information", {}).then((res) => {
      		if (res.data.answer == 'success') {
				  this.html = res.data.information
			  }
    	});
	}
};
</script>