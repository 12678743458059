<template>
    <div>
        <v-btn @click="exportAll()" :loading="loading">выгрузить все</v-btn>
        <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>
    </div>
</template>

<script>
import { bus } from '../main'
import ConfirmDialogue from './ConfirmDialogue.vue'
export default {
    components: {
        ConfirmDialogue,
    },
    data(){
        return {
        loading: false
        }
    }, 
    methods: {
       async exportAll() {
           this.loading = true
            const ok = await this.$refs.confirmDialogue.show({
                message: 'Вы уверены, что хотите выгрузить информацию по всем профилям?',
                okButton: 'Начать',
            })
            /* let ok = true */
            let id = 123
            if (ok) {
                this.$axios
                    .post(
                        '/api/downloadListAll/',
                        {
                        },
                        { responseType: 'arraybuffer' }
                    )
                    .then((data) => {
                        var fileURL = window.URL.createObjectURL(
                            new Blob([data.data], {
                                type: 'vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
                            })
                        )
                        var fileLink = document.createElement('a')
                        fileLink.href = fileURL
                        fileLink.setAttribute(
                            'download',
                            `${id}-${new Date().toLocaleString('ru-RU')}.xlsx`
                        )
                        document.body.appendChild(fileLink)
                        fileLink.click()
                        bus.$emit("snackbar", 'Файл загружается');
                        this.loading = false
                    })
            }
        }
    }
}
</script>
<style>

</style>