<template>
    <transition name="fade">
        <div class="popup-modal" v-show="isVisible">
            <div class="window">
                <slot></slot>
            </div>
        </div>
    </transition>
</template>
<script>
export default {
    name: 'PopupModal',
    props: {
        msg: String,
    },
    data() {
        return {
            isVisible: false,
        }
    },
    methods: {
        open() {
            this.isVisible = true
        },

        close() {
            this.isVisible = false
        },
    },
}
</script>
<style>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
.popup-modal {
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    z-index: 1;
}

.window {
    min-width: 450px;
    max-width: 480px; 
    margin-left: auto;
    margin-right: auto;
}
</style>